import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable, of, iif } from 'rxjs';
import { switchMap, catchError, map, tap, filter, withLatestFrom, delay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as RoutesDefinitions from 'apps/middle/src/app/routes-definitions';
import { TemplatesService } from 'apps/middle/src/app/shared-module/services';
import { TemplateDomain } from 'apps/middle/src/app/shared-module/enums';
import { ICanDeleteTemplateItem, ITemplateItem } from 'apps/middle/src/app/shared-module/models';
import { AppConstants } from 'apps/middle/src/app/app.constants';
import { scrollToTop } from '@aston/foundation';

import { FactorFeaturesStore } from '../../factor-features-module/stores/factor-features';
import { SharedTemplatesStore } from '../../shared-module/stores';
import { AppStoreActions } from '../app-store';
import { CorrelationParams, aggregate } from '../models/correlated-actions.model';

import * as featureActions from './actions';
import * as featureSelectors from './selectors';


@Injectable({
	providedIn: 'root'
})
export class DunningTemplateDetailStoreEffects {
	constructor(
		private actions$: Actions,
		private store: Store,
		protected modalService: NgbModal,
		private featuresStore: FactorFeaturesStore,
		private templatesService: TemplatesService,
		private templatesStore: SharedTemplatesStore,
		private router: Router) {
	}

	InitEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.InitRequest),
		switchMap(action => {
			const correlationParams: CorrelationParams = action.correlationParams;
			correlationParams.parentActionType = action.type;

			return [
				featureActions.LoadFormModelRequest({id: action.templateId, correlationParams: correlationParams}),
				AppStoreActions.Execute(() => this.templatesStore.loadAllTemplates()),
				AppStoreActions.Execute(() => this.templatesStore.loadClips()),
				AppStoreActions.Execute(() => this.featuresStore.reload()),
			];
		}),
		catchError(error => of(featureActions.InitFailure({error})))
	));

	InitSuccessEffect$: Observable<Action> = createEffect(() => this.actions$
	.pipe(
		ofType(featureActions.InitRequest),
		// Create an aggregated action listener
		// it will observe the race of all specified observable
		// and complete when all these actions are dispatched.
		// Note that individual actions workflow still apply.
		aggregate(
			this.actions$.pipe(ofType(featureActions.LoadFormModelSuccess)),
			this.actions$.pipe(ofType(featureActions.InitFailure)),
		),
		map(_ => featureActions.InitSuccess()),
		catchError(error => of(featureActions.InitFailure({ error })))
	));

	loadFormModelRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadFormModelRequest),
		switchMap(action => ( action.id ?
			this.templatesService.getTemplate(action.id) :
			this.templatesService.getTemplateDefault() )
		.pipe(
			map(formModel => featureActions.LoadFormModelSuccess({entity: formModel, correlationParams: action.correlationParams})),
			catchError(error => of(featureActions.LoadFormModelFailure({ error })))
		)
		)
	));

	createTemplateRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CreateFormModelRequest),
		switchMap(action => this.templatesService.saveTemplate(action.entity).pipe(
			map(newTemplateId => featureActions.CreateFormModelSuccess(newTemplateId)),
			catchError(error => of(featureActions.CreateFormModelFailure({ error })))
		))
	));

	saveTemplateSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.CreateFormModelSuccess),
		tap(_ => this.router.navigate([RoutesDefinitions.getDunningTemplatesListRouteFullPath()]))
	), {dispatch: false});

	updateTemplateConfirm$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFormModelConfirm),
		switchMap(action =>
			of(AppStoreActions.OpenConfirmationModal({textsKey: `Dunning.Confirmations.UpdateTemplate.`},
				featureActions.UpdateFormModelRequest(action.entity))
			))
	));

	updateTemplateRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFormModelRequest),
		switchMap(action => this.templatesService.updateTemplate(action.entity).pipe(
			map(_ => featureActions.UpdateFormModelSuccess({entity: action.entity, navigateToList: true })),
			catchError(error => of(featureActions.UpdateFormModelFailure({ error })))
		))
	));

	navigateAfterUpdateTemplateSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UpdateFormModelSuccess),
		filter(action => action.navigateToList),
		map(_ => this.router.navigateByUrl(RoutesDefinitions.getDunningTemplatesListRouteFullPath()))
	), {dispatch: false});

	useTemplateRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.UseTemplateRequest),
		switchMap(action => this.templatesService.getTemplate(action.template.id).pipe(
			map(template => featureActions.UseTemplateSuccess({template: template, withProps: action.withProps})),
			catchError(error => of(featureActions.UseTemplateFailure({ error })))
		))
	));

	duplicateTemplateRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DuplicateTemplateRequest),
		switchMap(action => iif(() => action.isFormDirty,
			of(AppStoreActions.OpenConfirmationModal({textsKey: `Dunning.Confirmations.DuplicateTemplate.`},
				featureActions.DuplicateTemplateSuccess(action.id))),
			of(featureActions.DuplicateTemplateSuccess(action.id))
		))
	));

	duplicateTemplateSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DuplicateTemplateSuccess),
		map(action => this.router.navigate([RoutesDefinitions.getDunningTemplateDuplicateRouteFullPath()],
			{queryParams: {[ RoutesDefinitions.DunningTemplateDuplicateRouteParam ]: action.id}}))
	), {dispatch: false});

	leaveFormRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LeaveFormRequest),
		switchMap(action => iif(() => action.param,
			of(AppStoreActions.OpenConfirmationModal({textsKey: `Dunning.Confirmations.TemplateFormLeave.`},
				featureActions.LeaveFormSuccess())),
			of(featureActions.LeaveFormSuccess())
		))
	));

	leaveFormSuccess$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LeaveFormSuccess),
		delay(100),
		map(_ => this.router.navigate([RoutesDefinitions.getDunningTemplatesListRouteFullPath()]))
	), {dispatch: false});


	// Seller Communication, Survey or Invoicing template
	// No risk of template used in another context
	deleteTemplateConfirm$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteTemplateItemConfirm),
		filter(action => action.entity.domain === TemplateDomain.SellerCommunication ||
										 action.entity.domain === TemplateDomain.Survey ||
										 action.entity.domain === TemplateDomain.Invoicing),
		switchMap(action => this.deleteConfirmation(action.entity, action.shouldRedirect)))
	);

	// Super Debtor Dunning template
	deleteTemplateSuperDebtorDunningConfirm$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteTemplateItemConfirm),
		filter(action => action.entity.domain === TemplateDomain.SuperDebtorDunning),
		switchMap(action => this.templatesService.canDeleteTemplate(action.entity.id).pipe(
			switchMap((canDeleteModel) => this.deleteConfirmationSuperDebtorDunning(canDeleteModel, action.entity, action.shouldRedirect)),
			catchError(error => of(featureActions.DeleteTemplateItemFailure({ error })))
		))
	));

	// Seller Dunning template
	deleteTemplateSellerDunningConfirm$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteTemplateItemConfirm),
		filter(action => action.entity.domain === TemplateDomain.SellerDunning),
		switchMap(action => this.templatesService.canDeleteTemplate(action.entity.id).pipe(
			switchMap((canDeleteModel) => this.deleteConfirmationSellerDunning(canDeleteModel, action.entity, action.shouldRedirect)),
			catchError(error => of(featureActions.DeleteTemplateItemFailure({ error })))
		))
	));

	deleteTemplateRequest$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteTemplateItemRequest),
		switchMap(action => this.templatesService.deleteTemplate(action.entity.id).pipe(
			map(() => featureActions.DeleteTemplateItemSuccess({entity: action.entity, shouldRedirect: action.shouldRedirect})),
			catchError(error => of(featureActions.DeleteTemplateItemFailure({ error })))
		))
	));

	deleteTemplateSuccess$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteTemplateItemSuccess),
		switchMap(action => action.shouldRedirect ?
			[ AppStoreActions.Execute(() => this.templatesStore.loadAllTemplates()), featureActions.DeleteTemplateRedirect() ] :
			[ AppStoreActions.Execute(() => this.templatesStore.loadAllTemplates()), AppStoreActions.Execute(() => this.templatesStore.loadTemplatesTable()), ]
		)
	));

	deleteTemplateRedirect$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.DeleteTemplateRedirect),
		map(_ => this.router.navigate([RoutesDefinitions.getDunningTemplatesListRouteFullPath()]))
	), {dispatch: false});

	resetClipsConfirm$: Observable<Action> = createEffect(() => this.actions$.pipe(
		ofType(featureActions.ResetContentConfirm),
		withLatestFrom(this.store.select(featureSelectors.selectFormModel)),
		switchMap(([action, current]) => of(AppStoreActions.OpenConfirmationModal(
			{textsKey: `Dunning.Confirmations.CleanTemplatesWithClips.`},
			featureActions.UpdateFormModelSuccess({
				entity: {
					...current,
					...action.entity,
					subject: ' ', // black magic fuckery !
					content: ' ', // on Firefox, an empty string does not trigger changes ?!
					footer: ' '   // https://dev.azure.com/astonitf/LBP/_workitems/edit/12212
				}
			}),
			featureActions.UpdateFormModelSuccess({
				entity: {
					...current,
					...action.entity,
					...action.rollbackProps
				}
			}))
		))
	));

	failures$ = createEffect(() => this.actions$.pipe(
		ofType(featureActions.LoadFormModelFailure,
			featureActions.CreateFormModelFailure,
			featureActions.UpdateFormModelFailure,
			featureActions.DeleteTemplateItemFailure),
		map(_ => AppStoreActions.Execute(() => scrollToTop()))
	));

	deleteConfirmationSuperDebtorDunning(canDeleteModel: ICanDeleteTemplateItem, entity: ITemplateItem, shouldRedirect: boolean) {
		return canDeleteModel.canBeDeleted ?
			this.deleteConfirmation(entity, shouldRedirect) :
			of(AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.DeleteSuperDebtorDunningTemplateImpossible${canDeleteModel.dunningScenariosUsingIt && canDeleteModel.dunningScenariosUsingIt.length > 1 ? 'Plural' : ''}.`,
				hideCancelBtn: true,
				confirmBtnText: 'Actions.Close',
				questionParams: {
					data: {
						scenarios: canDeleteModel.dunningScenariosUsingIt.join(AppConstants.LIST_ENUM_SEP_CHAR),
						scenarioCount: canDeleteModel.dunningScenariosUsingIt.length,
					}
				}
			})
		);
	}

	deleteConfirmationSellerDunning(canDeleteModel: ICanDeleteTemplateItem, entity: ITemplateItem, shouldRedirect: boolean) {
		return canDeleteModel.canBeDeleted ?
			this.deleteConfirmation(entity, shouldRedirect) :
			of(AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.DeleteSellerDunningTemplateImpossible.`,
				hideCancelBtn: true,
				confirmBtnText: 'Actions.Close'
			})
		);
	}

	deleteConfirmationTransferValidation(entity: ITemplateItem, usedId: number, shouldRedirect: boolean) {
		return entity.id === usedId ?
			of(AppStoreActions.OpenConfirmationModal({
				textsKey: 'Dunning.Confirmations.DeleteSuperDebtorTransferValidationTemplateImpossible.',
				hideCancelBtn: true,
				confirmBtnText: 'Actions.Close'
			})) :
			this.deleteConfirmation(entity, shouldRedirect);
	}

	deleteConfirmation(entity: ITemplateItem, shouldRedirect: boolean) {
		return of(AppStoreActions.OpenConfirmationModal({
				textsKey: `Dunning.Confirmations.DeleteTemplate.`,
				questionParams: {data: {name: entity.name}}
			},
			featureActions.DeleteTemplateItemRequest(entity, null, shouldRedirect)
		));
	}
}
